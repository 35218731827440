.container {
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
}

.policyImage {
  padding:  1rem; 
}

.policyContainer {
  padding: 0 5rem;
  margin-top: 3rem;
}

.policyContainer h2 {
  color: var(--primary);
  text-align: center;
  margin-top: 1rem;
}

.policyContainer h3 {
  color: var(--primary);
  font-weight: 600;
  margin-top: 2rem;
  font-size: 16px;
}

.policyContainer h3.titleCenterUnderline {
  text-align: center;
  text-decoration: underline;
  font-size: 1.4rem;
}

.policyContainer li {
  font-size: 14px;
  color: #000;
  margin: 6px 0;
}

.policyContainer ol {
  list-style-position: outside; /* Ensures the marker is inside the list item */
}

.policyContainer ol li::marker {
  color: rgb(143, 143, 143); /* Change the number color */
}

.bookNowBtnStyle {
  align-self: center;
  margin-bottom: 2vh;
}

.footerStyle {
  background-color: var(--primary);
  margin-top: 1rem;
}

.footerStyle a,
.footerStyle h3,
.footerStyle h2 {
  color: #fff;
}

.footerStyle a:hover {
  color: #000;
}

@media (max-width: 768px) {
  .container {
  }

  .policyContainer {
    padding: 0 1rem;
    margin-top: 3rem;
  }

  .policyContainer h2 {
    text-align: center;
  }

  .policyContainer h3 {
    margin-top: 1rem;
    font-size: 1rem;
  }

  .policyContainer h3.titleCenterUnderline {
    text-align: center;
    text-decoration: underline;
    font-size: 1.3rem;
  }

  .policyContainer ol {
    margin-left: 1px;
  }

  .policyContainer ul {
    margin: 0 0 0 5px;
    padding: 0;
  }
  
}
